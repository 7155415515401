import React, { useState } from "react";
import "./Banner.scss";
import image1 from "../../assets/images/image1.png";
import image2 from "../../assets/images/image2.png";
import image3 from "../../assets/images/image3.png";
import image4 from "../../assets/images/image4.png";

const images = [
  { src: image1, url: "https://tshentrofivem.web.app/product-details/F9W1SYkV53nx89V0txLD" },
  { src: image2, url: "https://tshentrofivem.web.app/product-details/F9W1SYkV53nx89V0txLD" },
  { src: image3, url: "https://tshentrofivem.web.app/product-details/F9W1SYkV53nx89V0txLD" },
  { src: image4, url: "https://tshentrofivem.web.app/product-details/F9W1SYkV53nx89V0txLD" }
];

const Banner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="banner">
      <button className="banner__arrow banner__arrow--left" onClick={handlePrev}>
        &#8249; {/* Left arrow */}
      </button>
      <div className="banner__carousel">
        <a href={images[currentIndex].url} target="_blank" rel="noopener noreferrer">
          <img src={images[currentIndex].src} alt="carousel" width="100%" height="500px" />
        </a>
      </div>
      <button className="banner__arrow banner__arrow--right" onClick={handleNext}>
        &#8250; {/* Right arrow */}
      </button>
    </div>
  );
};

export default Banner;