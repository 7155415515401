import React, { useState } from 'react';
import './ChatAssistant.scss';

const ChatAssistant = () => {
  const [isOpen, setIsOpen] = useState(false); // State to toggle the chat window
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');

  const handleSendMessage = () => {
    if (userInput.trim()) {
      setMessages([...messages, { text: userInput, sender: 'user' }]);
      setUserInput(''); // Clear input after sending
    }
  };

  return (
    <div>
      {/* Floating button */}
      {!isOpen && (
        <div className="chat-bubble" onClick={() => setIsOpen(true)}>
          <span>💬</span> {/* Emoji or chat icon */}
        </div>
      )}

      {/* Chat box */}
      {isOpen && (
        <div className="chat-assistant">
          <div className="chat-header">
            <h2>AI Assist</h2>
            <button className="close-btn" onClick={() => setIsOpen(false)}>✕</button>
          </div>

          <div className="chat-body">
            <div className="intro">
              <div className="glowing-ball"></div>
              <h3>What do you want to know about James?</h3>
            </div>

            <div className="predefined-questions">
              <button>Generate Summary</button>
              <button>Are they a good fit for my job post?</button>
              <button>What is their training style?</button>
              <button>Show more</button>
            </div>

            <div className="chat-messages">
              {messages.map((msg, index) => (
                <div key={index} className={`message ${msg.sender}`}>
                  {msg.text}
                </div>
              ))}
            </div>
          </div>

          <div className="chat-footer">
            <input
              type="text"
              placeholder="Ask me anything..."
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
            />
            <button onClick={handleSendMessage}>Send</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatAssistant;
