import React from "react";
import "./Footer.scss";
import { BsGithub } from "react-icons/bs"; // Assuming you're using Github only
import { useLocation } from "react-router-dom";
import ChatAssistant from "../aiAssistant/ChatAssistant"; // Import the assistant

const Footer = () => {
  const location = useLocation();

  function pathMatchRoute(route) {
    return route === location.pathname;
  }

  return (
    <div
      className={`${
        pathMatchRoute("/login") ||
        pathMatchRoute("/register") ||
        pathMatchRoute("/reset") ||
        pathMatchRoute("/cart") ||
        pathMatchRoute("/checkout-success") ||
        pathMatchRoute("/order-history")
          ? "margin"
          : "no-margin"
      }`}
    >
      <div className="footer">
        <div className="footer__links">
          <a
            href="https://github.com/TshentroTech"
            target="_blank"
            rel="noreferrer"
          >
            <BsGithub
              style={{
                background: "transparent",
                fontSize: "25px",
                color: "#fff",
              }}
              className="icon"
            />
          </a>
        </div>
        <div className="footer__links-alt">
          <p
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
            className="footer__light-color"
          >
            © 2025 Tshentro.tech. All rights Reserved.
          </p>
          |
          <a
            href="https://mail.google.com/mail/u/1/?view=cm&amp;fs=1&amp;to=support.tech@tshentro.me&amp;tf=1"
            className="footer__link"
            target="_blank"
            rel="noreferrer"
          >
            <span className="footer__light-color">Email:</span>{" "}
            support.tech@tshentro.me
          </a>
        </div>
      </div>

      {/* Add the Chat Assistant below the footer links */}
      <div className="chat-assistant-section">
        
        <ChatAssistant /> {/* This renders the AI assistant */}
      </div>
    </div>
  );
};

export default Footer;
